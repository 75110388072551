import axios, { AxiosError } from 'axios'
import { identity } from 'ramda'
import { queryAll } from 'lambda-dom'

import { bugsnagClient } from '@app-services'
import { AjaxFormConfig, AjaxFormErrors, IAjaxForm } from './ajax-form.types'

const noop = () => undefined

export class AjaxFormComponent implements IAjaxForm {

    constructor(
        public readonly form: HTMLFormElement,
        public readonly config: AjaxFormConfig,
    ) {
        if (form.dataset.ajaxFormInitialized) {
            return
        }

        form.addEventListener('submit', this.submitHandler)
        form.dataset.ajaxFormInitialized = 'true'
    }

    private readonly submitHandler = async (event: Event): Promise<void> => {
        event.preventDefault()
        await this.submit()
    }

    // ------------------------------------------------------------------------------
    //      API methods
    // ------------------------------------------------------------------------------

    public destroy(): void {
        this.form.removeEventListener('submit', this.submitHandler)
    }

    // ------------------------------------------------------------------------------
    //      Implementation (protected)
    // ------------------------------------------------------------------------------

    /**
     * Attempts to submit the form with XHR. Lets the `authorizeSubmit` function
     * from config decide whether the form may be submitted to the server.
     */
    protected async submit(): Promise<void> {
        this.setSubmittingState(true)
        this.clearErrors()

        try {
            const data = await (this.config.prepareSubmit || identity)(
                new FormData(this.form),
            )

            // TODO: `axios[this.config.method]`

            await axios.post(this.config.action, data, { headers: this.config.requestHeaders }).then(
                (response) => this.handleSuccess(response.data),
                (error: AxiosError) => this.handleErrors(error.response?.data || {}),

                // TODO: How best to account for HTTP errors other than 422?
            )

        } catch (err: unknown) {
            bugsnagClient.notify(err as string)
        }

        this.setSubmittingState(false)
    }

    /**
     * Handles the HTTP success response. It'll first internally reset the form
     * fields state and clear any errors that are displayed, and then call
     * `handleSuccess` from config to perform additional user-defined tasks,
     * if defined.
     */
    protected handleSuccess(responseData: any): void {
        this.form.reset()
        this.clearErrors();

        (this.config.handleSuccess || noop)(responseData, this)
    }

    /**
     * Handles the HTTP error response. It'll first internally handle the insertion
     * of errors using template functions from config, and then call `handleErrors`
     * from config to perform additional user-defined tasks, if defined.
     */
    protected handleErrors(errorData: any): void {
        const transformedResponse = this.config.transformErrorResponse(errorData)
        this.displayErrors(transformedResponse);

        (this.config.handleErrors || noop)(transformedResponse)
    }

    /**
     * Displays the given AjaxFormErrors object in the DOM subtree of the form
     * element. Uses the `insertError` function from config to insert the error
     * text into the DOM.
     */
    protected displayErrors(errors: AjaxFormErrors): void {
        let numInputErrorsDisplayed = 0

        // Input errors
        for (const [inputName, message] of Object.entries(errors)) {

            // Iterates a collection of elements to account for the possibility
            // of multiple elements (eg. 1 for desktop, another for mobile). All
            // elements must match the same selector for this to take effect.
            for (const element of this.getErrorElements(inputName)) {
                this.config.insertError(element, message)
                numInputErrorsDisplayed++
            }
        }

        // General errors
        const generalErrorMessageElement = document
            .querySelector<HTMLElement>('.ajax-form__general-error-message')

        if (! generalErrorMessageElement) {
            return
        }

        if (errors['g-recaptcha-response']) {
            this.config.insertError(generalErrorMessageElement, errors['g-recaptcha-response'])
            numInputErrorsDisplayed++
        }

        if (! numInputErrorsDisplayed) {
            this.config.insertError(
                generalErrorMessageElement,
                'An unknown error occurred. Please reload the page and try again.',
            )
        }
    }

    /**
     * Clears all errors from the DOM subtree of the form. Uses the
     * `errorElementSelector` function (without input name) from config to obtain
     * the list of all error elements inside the form. Then uses `clearError` from
     * config to reset the error element's content / state.
     */
    protected clearErrors(): void {
        const selector = this.config.errorElementSelector()

        const elements = queryAll<HTMLElement>(selector, this.form)

        for (const element of elements) {
            this.config.clearError(element)
        }
    }

    /**
     * Gets all error elements in the DOM subtree of the form matching the given
     * input name.
     */
    protected getErrorElements(inputName: string): HTMLElement[] {
        return queryAll<HTMLElement>(
            this.config.errorElementSelector(inputName),
            this.form,
        )
    }

    protected setSubmittingState(isSubmitting: boolean): void {
        this.form.classList.toggle('ajax-form--submitting', isSubmitting)
        this.form.querySelectorAll<HTMLInputElement | HTMLButtonElement>('[type="submit"]')
            ?.forEach((submitButton) => submitButton.disabled = isSubmitting)
    }
}
